import React, { Suspense, lazy } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Navigation } from './components/Navigation';
import { LoadingSpinner } from './components/LoadingSpinner';

const Calculator = lazy(() => import('./components/Calculator').then(m => ({ default: m.Calculator })));
const Terms = lazy(() => import('./pages/Terms').then(m => ({ default: m.Terms })));
const Privacy = lazy(() => import('./pages/Privacy').then(m => ({ default: m.Privacy })));
const About = lazy(() => import('./pages/About').then(m => ({ default: m.About })));
const FAQ = lazy(() => import('./pages/FAQ').then(m => ({ default: m.FAQ })));
const ContractorPortal = lazy(() => import('./components/ContractorPortal').then(m => ({ default: m.ContractorPortal })));
const ContractorOnboarding = lazy(() => import('./components/ContractorOnboarding').then(m => ({ default: m.ContractorOnboarding })));
const CustomerDashboard = lazy(() => import('./components/CustomerDashboard').then(m => ({ default: m.CustomerDashboard })));
const STRCoHosting = lazy(() => import('./pages/STRCoHosting').then(m => ({ default: m.STRCoHosting })));
const NotFound = lazy(() => import('./pages/NotFound').then(m => ({ default: m.NotFound })));

function App() {
  return (
    <>
      <Navigation />
      <Suspense 
        fallback={
          <div className="min-h-screen flex items-center justify-center">
            <LoadingSpinner size="large" />
          </div>
        }
      >
        <Routes>
          <Route path="/" element={<Calculator />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/about" element={<About />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contractor-portal" element={<ContractorPortal />} />
          <Route path="/contractor-signup" element={<ContractorOnboarding />} />
          <Route path="/customer-dashboard" element={<CustomerDashboard />} />
          <Route path="/str-cohosting" element={<STRCoHosting />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;